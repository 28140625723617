import React, { Component } from 'react';
// import { connect } from 'react-redux';
import {Layout, Row, Col, Divider, Card} from 'antd';
import Brands from './components/home/Brands';

function mapStateToProps(state) {
    return {

    };
}

class Contacts extends Component {
    render() {
        return (
            <Layout className="contacts_cont" style={{ background:"linear-gradient(90deg, #1CB5E0 0%, #000851 100%)"}}>
                <Row justify="center" align="bottom">
                    <Col xs={20} sm={20} md={10} lg={4} className="contacts">
                        <img className="logofooter" src="/assets/img/logo.gif"/>
                        <Divider orientation="left" className="devider" ></Divider>
                    </Col>
                    <Col xs={20} sm={20} md={10} lg={15} style={{textAlign:'center'}}>
                        <h1 className="contact_ttl">Contact us today, for services and prompt support</h1>
                        <Divider className="devider"></Divider>
                    </Col>

                </Row>
                <Row justify="center">
                    <Col xs={20} sm={20} lg={7} className="headOffice">
                        <Card title="Head Office" bordered={true} hoverable={true} className="contact_card">
                            <h3>Plot 343/345 Dunga Street</h3>
                            <h3>P. O. Box 7512 Dar es Salaam, Tanzania</h3>
                            <table>
                                <tr>
                                    <th>Tel:</th>
                                    <td>+255 22 2760037-8</td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>+255 22 2762429</td>
                                </tr>
                                <tr>
                                    <th>Fax:</th>
                                    <td>+255 22 2760293</td>
                                </tr>
                            </table>
                        </Card>
                        
                    </Col>
                    <Col xs={20} sm={20} lg={7} className="headOffice">
                        <Card title="Arusha Branch" bordered={true} hoverable={true} className="contact_card">
                            <h3>Plot 138/01, Block GG</h3>
                            <h3>Old Moshi Road, Kijenge</h3>
                            <h3>P. O. Box 11484 Arusha, Tanzania</h3>
                            <table>
                                <tr>
                                    <th>Tel:</th>
                                    <td>+255 22 2548717</td>
                                </tr>
                                <tr>
                                    <th>Fax:</th>
                                    <td>+255 22 2760293</td>
                                </tr>
                            </table>
                        </Card>
                        
                    </Col>
                </Row>
                <Brands />
            </Layout>
        );
    }
}

// export default connect(
//     mapStateToProps,
// )(Contacts);

export default Contacts;