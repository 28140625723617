import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Row,Col,Button} from 'antd';
import {
    PhoneOutlined
  } from '@ant-design/icons';


class ProjectsList extends Component {


    render(){


        return(
            <div>
                <Row align={"middle"} className="project_row">
                    <Col xs={24}  sm={24} md={12}  className="projects_txt">
                        <h1>Mwalimu Nyerere International Airport</h1>
                        <h2>2018</h2>
                        <h3>The Mwalimu Nyerere internation Airport in Dar-es-Salaam, Terminal III handles international and domestic flights.</h3>
                        <Link to="/contacts"><Button type={'primary'} shape={'round'} size={'large'}>Call us <PhoneOutlined /></Button></Link>
                    </Col>
                    <Col xs={24}  sm={24} md={12} >
                        <img src="assets/img/projects/airport.jpg" className="projects_img"/>
                    </Col>
                </Row>
                <Row align={"middle"}  className="project_row">
                    <Col xs={24}  sm={24} md={12} style={{textAlign:"right"}}>
                        <img src="assets/img/projects/sgr.jpg" className="projects_img"/>
                    </Col>
                    <Col xs={24}  sm={24} md={12}  className="projects_txt">
                        <h1>Standard Gauge Railway - Tanzania</h1>
                        <h2>2017</h2>
                        <h3>Phase one, from Dar es salaam to Morogoro (300 km).</h3>
                        <Link to="/contacts"><Button type={'primary'} shape={'round'} size={'large'}>Call us <PhoneOutlined /></Button></Link>
                    </Col>
                </Row>

                <Row align={"middle"} className="project_row">
                    <Col xs={24}  sm={24} md={12}  className="projects_txt">
                        <h1>Pan African Postal Union</h1>
                        <h2>2021</h2>
                        <h3>The Pan African Postal Union in Arusha, Tanzania and 18 storey facility.</h3>
                        <Link to="/contacts"><Button type={'primary'} shape={'round'} size={'large'}>Call us <PhoneOutlined /></Button></Link>
                    </Col>
                    <Col xs={24}  sm={24} md={12} >
                        <img src="assets/img/projects/papu.jpg" className="projects_img"/>
                    </Col>
                </Row>
                <Row align={"middle"}  className="project_row">
                    <Col xs={24}  sm={24} md={12} style={{textAlign:"right"}}>
                        <img src="assets/img/projects/bunge.jpg" className="projects_img"/>
                    </Col>
                    <Col xs={24}  sm={24} md={12}  className="projects_txt">
                        <h1>National Parliament - Tanzania</h1>
                        <h2>2016</h2>
                        <h3>The Natioanl Parliament facility in Dodoma, Tanzania.</h3>
                        <Link to="/contacts"><Button type={'primary'} shape={'round'} size={'large'}>Call us <PhoneOutlined /></Button></Link>
                    </Col>
                </Row>

            </div>
        )
    }
}

export default ProjectsList;