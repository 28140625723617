import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {Layout, Menu, Row, Col, Divider, Space} from 'antd';
import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";

// Import pages
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Projects from './pages/Projects';
import NoMatch from './pages/NoMatch';

const {Header, Content, Footer} = Layout;

function App() {
  const currentYear= new Date()

  return (
    <Router>
      <Layout>

        <Header className="header topHeader" style={{ position: 'fixed', zIndex: 100, width: '100%', padding:0  }}>
          <Row justify="space-between" style={{lineHeight:"62px", backgroundColor:"#fff"}}>
            <Col xs={{span:24 ,offset:2}} sm={{span:12 ,offset:2}} md={{span:10,offset:1}} lg={{span:10,offset:1}}>
              <img className="logo" src="/assets/img/logo.gif"/>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:10}} lg={{span:10}} style={{backgroundColor:'#dddae2',textAlign:'center'}}>
              Hotline: +255 22 276 0037-8 | email: sstl@sstl.co.tz
            </Col>
          </Row>
          <Row justify="end" style={{background:'#001529',padding:'0'}}>
            
            <Col span={10}>
              <Menu theme="dark" className="nav_menu" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" ><Link to="/" className="nav_link">Home</Link></Menu.Item>
                <Menu.Item key="2"><Link to="/about"  className="nav_link">About</Link></Menu.Item>
                <Menu.Item key="3"><Link to="projects"  className="nav_link">Projects</Link></Menu.Item>
                <Menu.Item key="4"><Link to="contacts"  className="nav_link">Contacts</Link></Menu.Item>
              </Menu>
            </Col>
          </Row>
          
        </Header>

        <Content className="content">
          <Switch>
            <Route exact path="/"><Home /></Route>
            <Route path="/about"><About /></Route>
            <Route path="/contacts"><Contacts /></Route>
            <Route path="/projects"><Projects /></Route>
            <Route><NoMatch /></Route>
          </Switch>
        </Content>

        <Footer className="footer" style={{}}>
          <Row  justify="center">
            <Col xs={24} sm={24} md={5} lg={5} >
              <img className="logofooter" src="/assets/img/logo.gif"/>
              
            </Col>
            <Col xs={12} sm={12} md={15} lg={3} style={{paddingTop:'20px'}}>
                <h4 className="q_links_ttle">Quick Links</h4>
                <div className="footer_links">
                  <Link to="/">Home</Link>
                  <Link to="/about">About</Link>
                  <Link to="/projects">Projects</Link>
                  <Link to="/contacts">Contacts</Link>
                </div>
            </Col>
            <Col xs={12} sm={12} md={15} lg={3} style={{paddingTop:'20px'}}>
                <h4 className="q_links_ttle">Admin</h4>
                <div className="footer_links">
                  <Link to="/">Webmail</Link>
                </div>
            </Col>
            <Col flex="auto" className="maintain">
              <h2 style={{padding:'20px',color:"rgb(214, 212, 212)"}}>"Maintaining Standards & Excellence For The Private Security Industry"</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="rights">
              <Divider></Divider>
              <Space split={<Divider type="vertical"/>}>
                <span></span>
                <span>Website by <a href="mailto:barakaxp@gmail.com"><span style={{fontSize:'.9em'}}>baraka</span>XP</a></span>
                <span>@ {currentYear.getFullYear()} SSTL GROUP</span>
                <span>All rights reserved</span>
                <span></span>
              </Space>
              
            </Col>
          </Row>
        </Footer>

      </Layout>
    </Router>
  );
}

export default App;
