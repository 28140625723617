import React, { Component } from 'react';
// import { connect } from 'react-redux';
import {Layout, Row, Col} from 'antd';
import ProjectsList from './components/projects/ProjectsList';

function mapStateToProps(state) {
    return {

    };
}

class Contacts extends Component {
    render() {
        return (   
            <Layout>
                <Row className="projects">
                    <Col span={24} className="projects">
                        <ProjectsList/>
                    </Col>
                </Row>
            </Layout>
        );
    }
}

// export default connect(
//     mapStateToProps,
// )(Contacts);

export default Contacts;