import React, { Component } from 'react';
// import { connect } from 'react-redux';
import {Layout,Row,Col} from 'antd';

import SlideShow from './components/home/SlideShow';
import Associations from './components/home/Associations';
import International from './components/home/International';
import AboutBrief from './components/about/AboutBrief';
import Strategic from './components/home/Strategic';
import Brands from './components/home/Brands';


function mapStateToProps(state) {
    return {

    };
}

class Home extends Component {
    render() {

        const theStyle={
            width:"60%"
        }

        const nowClassName ="homeabt"

        return (
            <Layout className="home_cont">
                <Row justify="center">
                    <Col span={24} className="forMobile">
                        <SlideShow/>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24}>
                        <AboutBrief spanLeft={20} spanRight={12} showRight={false} cstyle={theStyle} nowClassName={nowClassName} homeshow={false} showImg={true}/>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24} className="strategic-col">
                        <Strategic />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Brands />
                    </Col>
                </Row>
                {/* <Row justify="center">
                    <Col span={24}>
                        <International/>
                    </Col>
                </Row>
                <Row justify="center" className="association_cont">
                    <Col span={10} className="association">
                        <h1>Proffessional Membership and Authorities</h1>
                        <Associations/>
                    </Col>
                </Row> */}
            </Layout>
        );
    }
}

// export default connect(
//     mapStateToProps,
// )(Home);

export default Home;