import React, { Component } from 'react';
import Slider from "react-slick";
// import { connect } from 'react-redux';
import {Card,Row,Col, Collapse, Button } from 'antd';
import {Link} from "react-router-dom";
import {
    ArrowRightOutlined
  } from '@ant-design/icons';



const { Panel } = Collapse;
const { Meta } = Card;

class AboutBrief extends Component{

    render (props){

        const mediaQuery = window.matchMedia('(max-width:576px)')

        if (mediaQuery.matches){
            var settings = {
                className: "center",
                centerMode: true,
                infinite: true,
                centerPadding: "-35px",
                slidesToShow: 1,
                speed: 500,
                autoplay:true,
                autoplaySpeed:5000,
              };
        }
        else{
            var settings = {
                className: "center",
                centerMode: true,
                infinite: true,
                centerPadding: "-35px",
                slidesToShow: 4,
                speed: 500,
                autoplay:true,
                autoplaySpeed:5000,
              };
        }

        

        return(
            <Row justify={'space-around'}>
                <Col span={20} className="home_strategic" >
                    <div className="strategic_header">
                        <h1>Strategic Technological Partnership</h1>
                        <h2>We are well positioned to offer world best security products</h2>
                    </div>
                    <Slider {...settings}>
                        <div className="card_slide">
                            <Card
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="Intrusion Systems" src="/assets/img/tile/xray.svg" />}
                            >
                                <Meta title="VOTI" description="X-Ray Scanners" />
                            </Card>
                        </div>
                        <div className="card_slide">
                            <Card
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="Intrusion Systems" src="/assets/img/tile/intrusion.svg" />}
                            >
                                <Meta title="BOSCH" description="Intrusion Alarm Systems" />
                            </Card>
                        </div>
                        <div className="card_slide">
                            <Card
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="Fire Systems" src="/assets/img/tile/fire.svg" />}
                            >
                                <Meta title="BOSCH" description="Automatic Fire Detectors" />
                            </Card>
                        </div>
                        <div className="card_slide">
                            <Card
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="PA System" src="/assets/img/tile/pa.svg" />}
                            >
                                <Meta title="BOSCH" description="Public Address and Voice Alarm" />
                            </Card>
                        </div>
                        {/* <div className="card_slide">
                        <Card
                            hoverable
                            style={{ width: 300 }}
                            cover={<img alt="Hickvision" src="/assets/img/tile/camera.jpg" />}

                        >
                            <Meta title="CCTV Camera" description="Quality Camera" />
                        </Card>
                        </div> */}
                        {/*<div className="card_slide">
                         <Card
                            hoverable
                            style={{ width: 300 }}
                            cover={<img alt="Access Control" src="/assets/img/tile/access.jpg" />}

                        >
                            <Meta title="Europe Street beat" description="www.instagram.com" />
                        </Card>
                        </div> */}
                        {/* <div className="card_slide">
                            <Card
                                hoverable
                                style={{ width: 300 }}
                                cover={<img alt="Hickvision" src="/assets/img/tile/camera2.jpg" />}
                            >
                                <Meta title="Hickvision" description="CCTV Camera, high pixel" />
                            </Card>
                        </div> */}
                        {/* <div className="card_slide">
                        <Card
                            hoverable
                            style={{ width: 300 }}
                            cover={<img alt="Hickvision" src="/assets/img/tile/camera.jpg" />}

                        >
                            <Meta title="CCTV Camera" description="Quality Camera" />
                        </Card>
                        </div> */}
                        {/* <div className="card_slide">
                        <Card
                            hoverable
                            style={{ width: 300 }}
                            cover={<img alt="Access Control" src="/assets/img/tile/access.jpg" />}

                        >
                            <Meta title="Europe Street beat" description="www.instagram.com" />
                        </Card>
                        </div> */}
                        
                    </Slider>
                </Col>
            </Row>
        )
    }
}


export default AboutBrief;