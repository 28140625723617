import React, { Component, useNavigate } from 'react';
// import { connect } from 'react-redux';
import {Row,Col, Collapse, Button, Steps, Card} from 'antd';
import {Link} from "react-router-dom";
import {
    ArrowRightOutlined, HomeOutlined, HeartTwoTone
  } from '@ant-design/icons';

const { Panel } = Collapse;
const { Step } = Steps;

class AboutBrief extends Component{
    
    render (props){
        
        const nowClassName=this.props.nowClassName

        const long = 9;
        const short = 9;
        const height= "420px";



        return(
            <Row className="home_about">
                <Col  xs={24} sm={24} md={this.props.spanLeft} lg={this.props.spanLeft} >
                    {
                        !this.props.homeshow && 
                        (
                            <>
                                <h1 style={{textAlign:"center",fontSize:"3em",fontWeight:"bold"}}>We Maintain Standards & Excellence for the Private Security Industry</h1>
                                <Row align='center'>
                                    <Col  xs={long} sm={long} style={{backgroundImage:"URL('assets/img/vision.svg')", backgroundSize:"cover", backgroundPosition:"center"}}></Col>
                                    <Col  xs={short} sm={short}>
                                        <Card style={{height:height}}>
                                                <h1 style={{color:"gray",fontWeight:"bold"}}>Our Vision</h1>
                                            <p className='vision_mission'>SSTL Group's vision is to become major installer and integrator of electronic security control equipment and systems in Tanzania and the East African Community countries EAC. We are proud to be a truly Tanzania Company by contributing fully to the Community Security and maintaining a lead for the Private Security Industry Standards.</p>
                                                {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                                        </Card> 
                                    </Col>
                                </Row>
                                <Row  align='center'>
                                    <Col  xs={short} sm={short}>
                                        <Card style={{height:height}}>
                                            <h1 style={{color:"gray",fontWeight:"bold"}}>Our Mission</h1>
                                            <p className='vision_mission'>SSTL Group's mission is to employ private security industry standards and practices throughout our installation and integrating works. To apply international code of conducts, rules and regulations, work and perform out duties as professional security operatives.</p>
                                                {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                                            
                                        </Card>
                                    </Col>
                                    <Col  xs={long} sm={long}  style={{backgroundImage:"URL('assets/img/mission.svg')", backgroundSize:"cover",backgroundPosition:"center"}}></Col>
                                </Row>

                            </>
                        )
                    }
                    {
                        this.props.homeshow &&
                       ( 
                           <>
                            <Card>
                                <h1 style={{color:"gray",fontWeight:"bold"}}>Our Vision</h1>
                                <p>SSTL Group's vision is to become major installer and integrator of electronic security control equipment and systems in Tanzania and the East African Community countries EAC. We are proud to be a truly Tanzania Company by contributing fully to the Community Security and maintaining a lead for the Private Security Industry Standards.</p>
                                    {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                            </Card> 
                            <Card>
                                <h1 style={{color:"gray",fontWeight:"bold"}}>Our Mission</h1>
                                <p>SSTL Group's mission is to employ private security industry standards and practices throughout our installation and integrating works. To apply international code of conducts, rules and regulations, work and perform out duties as professional security operatives.</p>
                                {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                            </Card>
                           </>
                       )
                    }

                    {/* <Collapse defaultActiveKey={['1']} className={nowClassName}>
                        <Panel header={(<h3>Our Vision</h3>)} key="1">
                        <p>SSTL Group's vision is to become major installer and integrator of electronic security control equipment and systems in Tanzania and the East African Community countries EAC. We are proud to be a truly Tanzania Company by contributing fully to the Community Security and maintaining a lead for the Private Security Industry Standards.</p>
                        {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                        </Panel>
                        <Panel header={(<h3>Our Mission</h3>)} key="2">
                        <p>SSTL Group's mission is to employ private security industry standards and practices throughout our installation and integrating works. To apply international code of conducts, rules and regulations, work and perform out duties as professional security operatives.</p>
                        {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                        </Panel>
                        {/* <Panel header={(<h3>Our Values</h3>)} key="3">
                        <p>Company Values</p>
                        {!this.props.showRight && (<Link to="/about"><Button type={'primary'} shape={'round'} size={'large'}>MORE <ArrowRightOutlined /></Button></Link>)}
                        </Panel>
                    </Collapse> */}
                    {this.props.showRight && (
                        <Card bordered={true} hoverable style={{marginTop:"20px",padding:"20px"}}  title={(<h3>Our achievements</h3>)}>
                            <Steps direction="vertical" current={3}>
                                <Step title="Achievement 2019" description="Mwalimu Nyerere International Airport Terminal III" icon={<HeartTwoTone twoToneColor="#eb2f96"  />}  />
                                <Step title="Achievement 2017" description="Standard Gauge Railway" icon={<HeartTwoTone twoToneColor="#eb2f96"  />}  />
                                <Step title="Achievement 2002" description="Bunge Project" icon={<HeartTwoTone twoToneColor="#eb2f96"  />} />
                                <Step title="Registration 1998" description="SSTL Group Registration" icon={<HomeOutlined />}  />
                            </Steps>
                        </Card>
                    )}
                    
                </Col>
                {this.props.showImg && (
                    <Col xs={24} sm={24} md={3} lg={3}>
                        <img src="assets/img/about.svg" className="about_img"/>
                    </Col>
                )}

                {this.props.showRight && (
                <Col span={this.props.spanRight} className="about_chairman">
                    <img src="assets/img/chairman.png" className="chairman"/>
                    <h1>Executive Chairman's Statement</h1>
                    <p> SSTL Group was registered as specialized contractor class One with the Contractors Registration Board (CRB) in the fields of Security, ICT and Fire Preventions specialization. Our other focus is on installation of strategic security risk control management equipment
                        and systems within the private security industry parameters and beyond.</p>
                    <p>SSTL Group does not conduct man-guarding services, which is considered expensive with many challenges to current high technological banditry or robberies. Designing, supplying and integrating or security control equipment and systems is the expertise which aligns SSTL Group at the cutting edge of modern security practice by combining diverse technologies and systems to provide truly integrated security
                        solutions that give all our clients a total security blanket of safety and security at homes and work place.</p>
                    <p>Whatever your security requirement is, we shall advice and propose to you, a cost effective but a better solution both technically and proffessionally than any other installer or integrator. With SSTL Group, your security starts 
                        long before the first security equipment or system is installed at your place.</p>
                    <p>It all starts first with the better business relationship; SSTL Group should become your right partner for your safety and security at workplace or at home. We take your security requirements as our daily challenge and therefore take the roles and responsibility of your risks assesment into our daily performace and our duty of care to you.</p>
                    <p>SSTL Group as your security partner will participate from the start on designing a security system that for a long time in life will protect your people, your property your customers and your business. As SSTL Group Security Partner,
                        <ul>
                            <li>You will be offered with a range of security solutions.</li>
                            <li>You will be part of the security group with more than 30 year's experience in the private security industry.</li>
                            <li>You will be supported by professional engineers available on 24 Hours basis with international standards and many more.</li>
                        </ul>
                    </p>
                    <p>Whilst I have tried to explain to you as much as possible about each of SSTL Group's line of business in this website please do not hesitate to contact us if you need further information or details.</p>
                    <p>I welcome you aboard the British Security Industry Association (BSIA) and Tanzania Security Industry Association (TSIA), Tanzania Chamber of Commeerce Industry and Agriculture (TCCIA), Associate of Tanzania Employers (ATE) member group.</p>
                    <p>We lead the way for others to follow and always mind the gap bwtween SSTL Group rendered services and services rendered by others.</p>
                    
                    <h4>Almasi A. Maige (MP)</h4>
                </Col>)}

            </Row>
        )
    }
}


export default AboutBrief;