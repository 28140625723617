import react,{ Component } from "react";
import {Layout, Row, Col} from 'antd';

class Brands extends Component {

    render(){


        return (
            <Row justify={"space-around"} align={"middle"}>
                <Col span={24}>
                    <div className="brands">
                        <img src="assets/img/ate.png"/>
                        <img src="assets/img/bsia.png"/>
                        <img src="assets/img/tccia.png"/>
                        <img src="assets/img/tsia.png"/>
                        <img src="assets/img/voti_logo.png"/>
                        <img src="assets/img/bosch.png"/>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default Brands;