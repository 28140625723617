import React, { Component } from "react";
import Slider from "react-slick";
import {RightOutlined, LeftOutlined} from '@ant-design/icons';


function NextArrow(props) {
  const { onClick} = props;
  return (
    <div
      className="next_btn"
      onClick={onClick}
    ><RightOutlined/></div>
  );
}

function PrevArrow(props) {
  const {onClick} = props;
  return (
    <div
      className="previous_btn"
      onClick={onClick}
    ><LeftOutlined/></div>
  );
}


class SlideShow extends Component {
  render() {
    const settings = {
      dots: false,
      fade:true,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      autoplaySpeed:10000,
      pauseOnHover:false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    return (
      <div className="slideshow">
        <Slider {...settings}>
          <div className="slide">
            <div className="overlay">
              <div className="overlay_bg"></div>
              <h1 >High Quality Vision</h1>
              <h2>World's best CCTV cameras</h2>
            </div>
            <img src="assets/img/cctv_banner.svg"/>
          </div>
          <div className="slide">
            <div className="overlay">
              <div className="overlay_bg grad"></div>
              <h1>X-Ray Scanner</h1>
              <h2>World's scanners</h2>
            </div>
          <img src="assets/img/voti.svg"/>
          </div>
          <div className="slide">
            <div className="overlay">
              <div className="overlay_bg"></div>
              <h1>Access Control</h1>
              <h2>World's best CCTV cameras</h2>
            </div>
            <img src="assets/img/access.svg"/>
          </div>
          {/* <div className="slide">
            <div className="overlay">
              <div className="overlay_bg"></div>
              <h1>Barrie / Access Controll</h1>
              <h2>World best CCTV cameras</h2>
            </div>
            <img src="assets/img/barrier.svg"/>
          </div> */}
          <div className="slide">
            <div className="overlay">
              <div className="overlay_bg"></div>
              <h1>Barrier / Access Controll</h1>
              <h2>World's best vehicle barrier</h2>
            </div>
            <img src="assets/img/avonbarrier.svg"/>
          </div>
        </Slider>
      </div>
    );
  }
}


export default SlideShow;

// ******************** ADD THE BELOW CSS CODE TO CSS FILE  ******************************

// .slideshow{
//   background:cadetblue !important;
// }

// .slide{
//   height:60VH;
//   text-align: center;
//   display:flex;
//   align-items:baseline;
// }
// 
// .next_btn, .previous_btn {
//   position: absolute;
//   display: block;
//   padding: 5px;
//   font-size: 2.5em;
//   line-height: 0;
//   top: 50%;
//   -webkit-transform: translate(0,-50%);
//   -ms-transform: translate(0,-50%);
//   transform: translate(0,-50%);
//   cursor: pointer;
//   color: rgb(255, 255, 255);
//   border: none;
//   outline: 0;
//   background:rgb(75, 73, 73);
//   z-index:1 !important;
//   border-radius: 100%;
//   opacity: .1;
// }

// .next_btn:hover, .previous_btn:hover { opacity:1;}
// .next_btn {right: 25px;}
// .previous_btn {left: 25px;}