import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import {Background, VisionMission, Values} from '../About/Governance';
import AboutBrief from './components/about/AboutBrief';
import {Layout, Row, Col} from 'antd';


class About extends Component {
    render() {
        return (
            <Layout className="about">
                <Row justify="space-around">
                    <Col span={24}>
                        <AboutBrief spanLeft={7} spanRight={14} showRight={true} homeshow={true}/>
                    </Col>
                </Row>
            </Layout>
        );
    }
}

export default About;