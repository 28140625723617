import React, { Component } from "react";
import Slider from "react-slick";
import {Layout, Row, Col} from 'antd';

export default class CenterMode extends Component {
  render() {
    const settings = {
      className:'center tosts',
      centerMode: true,
      infinite: true,
      autoplay:true,
      centerPadding: "50px",
      slidesToShow: 3,
      speed: 7000,
      autoplaySpeed:7000,
      cssEase: "linear",
    };
    return (
      <Layout>
          <Row justify="center">
              <Col span={23}>
                
                <Slider {...settings}>
                <div>
                    <img src="assets/img/ate.png"/>
                </div>
                <div style={{backgroundColor:"#000"}}>
                    <img src="assets/img/bsia.webp"/>
                </div>
                <div>
                    <img src="assets/img/tccia.png"/>
                </div>
                <div>
                    <img src="assets/img/tsia.png"/>
                </div>
                </Slider>
            </Col>
        </Row>
      </Layout>
    );
  }
}